<template>
  <div>
    <div class="card">
      <h3 class="card-title">Crear Procedimiento</h3>
      <div class="cols2">
        <pd-input
          type="text"
          id="titulo"
          v-model="titulo"
          placeholder="Titulo"
          label="Título"
        ></pd-input>
      </div>
      <div class="cols2">
        <pd-input
          v-model="desde"
          id="desde"
          label="Desde"
          placeholder="Desde"
          type="date"
        ></pd-input>
        <pd-input
          v-model="hasta"
          id="desde"
          label="Hasta"
          placeholder="Hasta"
          type="date"
        ></pd-input>
      </div>
      <pd-input
        type="file"
        id="archivo"
        v-model="archivo"
        placeholder="Archivo"
        label="PDF"
      ></pd-input>
      <div class="form-group my-1">
        <label class="form-label">Contenido</label>
        <quill-editor
          v-model="contenido"
          :options="editorOption"
        ></quill-editor>
      </div>
      <div class="cols2">
        <div class="form-group my-1">
          <label class="form-label">Estado</label>
          <select id="publicado" class="form-control" v-model="publicado">
            <option :value="true">Publicado</option>
            <option :value="false">Sin publicar</option>
          </select>
        </div>
        <div class="form-group my-1">
          <label class="form-label">Requiere notificarse</label>
          <select
            id="requiere_firma"
            class="form-control"
            v-model="requiere_firma"
          >
          <option :value="true">SI</option>
          <option :value="false">NO</option>
          </select>
        </div>
      </div>
      <template v-if="requiere_firma">
        <div class="cols2">
          <div class="form-group my-1">
            <label class="form-label">Requiere que todos se notifiquen</label>
            <select class="form-control" id="all-users"
              v-model="firman_todos">
              <option :value="true">SI</option>
              <option :value="false">NO</option>
            </select>
          </div>
        </div>
        <div v-if="requiere_firma && !firman_todos" class="mb-2">
          <table-list
            :showFilter="true"
            :data="empleadosQueRequierenFirma"
            :header="personasTableList.headers"
            :body="personasTableList.body"
            :actionButton="personasTableList.actionButton"
            @rowClicked="deleteEmpleadoFromList"
          ></table-list>
        </div>
      </template>
      <button class="btn btn-primary" @click="save">Guardar</button>
    </div>
    <div class="noticias">
      <div class="card" :class="classNoticia">
        <h4 class="card-title">{{ titulo }}</h4>
        <div v-html="contenido"></div>
      </div>
    </div>

    <modal v-if="openAddEmpleado" @close="closeAddEmpleado">
      <AddEmpleadoParaNotificar
        slot="modal-body"
        :personas="listaDePersonasParaSeleccionar"
        :tablelist="personasTableList"
        @addEmpleado="addEmpleadoToList"
        />
    </modal>
  </div>
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";
import Swal from "sweetalert2";
import { NoticiasServices } from "../../noticias/services/NoticiasServices";
import { PersonasService } from '../../personas/services/PersonasServices';
import TableList from "@/components/elements/TableList";
import Modal from '@/components/elements/Modal'
import AddEmpleadoParaNotificar from "./AddEmpleadoParaNotificar.vue";
export default {
  name: "CrearProcedimiento",
  components: {
    quillEditor,
    TableList,
    Modal,
    AddEmpleadoParaNotificar
  },
  data() {
    return {
      titulo: "",
      contenido: "",
      archivo: null,
      tipo: "procedimiento",
      desde: null,
      hasta: null,
      editorOption: {
        theme: "snow",
      },
      publicado: true,
      requiere_firma: false,
      firman_todos: true,
      empleadosQueRequierenFirma: [],
      personas: [],
      openAddEmpleado: false,
      personasTableList: {
        headers:[
          { value: 'Legajo', sortable: true },
          { value: 'Persona', sortable: true },
          { value: 'Gerencia', sortable: true },
          { value: 'Area', sortable: true }
        ],
        body: [
          {
            class: 'w-100',
            data: [{ value: 'numero_legajo', class: '', label: 'Legajo' }]
          },
          {
            class: 'important',
            data: [{ value: 'nombre', class: "text-primary" }, { value: 'cuil', class: 'text-small text-secondary', mutator: (cuil) => { return PersonasService.formatCuil(cuil) } }]
          },
          {
            class: 'w-100',
            data: [{ value: 'gerencia.descripcion', label: 'Gerencia' }]
          },
          {
            class: 'w-100',
            data: [{ value: 'area.descripcion', label: 'Area' }]
          }
        ],
        actionButton: {
          text: "Añadir",
          action: () => {
            this.openAddEmpleado = true
          },
        },
      }
    };
  },
  watch: {
    desde() {
      if (this.desde) {
        let fecha_desde = new Date(this.desde);
        fecha_desde.setDate(fecha_desde.getDate() + 7);
        this.hasta = fecha_desde.toISOString().slice(0, 10);
      }
    },
  },
  computed: {
    classNoticia() {
      let clase = "";
      if (this.tipo) {
        clase = `card-${this.tipo}`;
      }
      return clase;
    },
    listaDePersonasParaSeleccionar(){
      return this.personas.filter(persona => {
        return !this.empleadosQueRequierenFirma.includes(persona);
      });
    }
  },
  created() {
    const today = new Date();
    this.desde = today.toISOString().slice(0, 10);
    this.hasta = today.toISOString().slice(0, 10);
    PersonasService.api.getAllForSelect().then((personas) => (this.personas = personas));
  },
  methods: {
    save() {
      const formData = new FormData();
      formData.append("titulo", this.titulo);
      formData.append("contenido", this.contenido);
      const archivo = document.querySelector("input#archivo");
      formData.append("archivo", archivo.files[0]);
      formData.append("tipo", this.tipo);
      formData.append("desde", this.desde);
      formData.append("hasta", this.hasta);
      formData.append("publicado", this.publicado);
      formData.append("requiere_firma", this.requiere_firma);
      if(this.requiere_firma && this.firman_todos){
        formData.append("empleados_notificables", JSON.stringify(this.personas));
      }else if(this.requiere_firma && !this.firman_todos){
        formData.append("empleados_notificables", JSON.stringify(this.empleadosQueRequierenFirma));
      }else{
        formData.append("empleados_notificables", "[]");
      }

      NoticiasServices.api
        .save(formData)
        .then((response) => {
          if (response.success) {
            Swal.fire("Éxito", "Se guardó correcamente", "success");
            this.$router.push({ name: "ListarProcedimientosAdmin" });
          } else {
            Swal.fire("Error", "No se pudo guardar", "error");
          }
        })
        .catch((error) => {
          Swal.fire(
            "Error",
            `<p>Error de servidor</p><p>${error}</p>`,
            "error"
          );
        });
    },
    deleteEmpleadoFromList(empleado){
      Swal.fire({
        title: "Confirme",
        html: `<p>¿Está seguro que quitar a <b>${empleado.nombre}</b> de la lista?</p>`,
        icon: "question",
        showConfirmButton: true,
        confirmButtonText: "Eliminar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.empleadosQueRequierenFirma = this.empleadosQueRequierenFirma.filter(persona => persona !== empleado)
        }
      });
    },
    addEmpleadoToList(empleado){
      this.empleadosQueRequierenFirma.push(empleado)
      this.empleadosQueRequierenFirma.sort((a,b) => a.id - b.id)
    },
    closeAddEmpleado(){
      this.openAddEmpleado = false
    }
  },
};
</script>
